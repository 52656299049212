<template>
  <div class="w-full h-full col items-center justify-center">
    <span class="text-5xl text-gray-500">404</span>
    <span class="text-xl text-gray-500">Oops...! We couldn't find that page.</span>

    <button @click="$router.push('/schedule')" class="mt-8 row items-center rounded-md border-2 border-zinc-400">
      <VueFeather type="home" size="20" />
      <span class="ml-2 mt-1 text-lg">Go Home</span>
    </button>
  </div>
</template>
<script>
import MobileLayout from "./MobileLayout.vue";
export default {
  components: {MobileLayout}
}
</script>