<!--Viewing more info about an activity only-->
<template>
  <WideLayout>
    <div class="row">
      <div class="grow w-5/6 col px-5 py-5 overflow-y-auto">
        <template v-if="loading">
          <h1>...</h1>
        </template>

        <template v-else>
          <p class="mb-2 required">Title:</p>
          <input type="text" class="text-2xl" v-model="activity.title">

          <p class="mt-4">Description (HTML)</p>
          <HTMLEditor v-model="activity.description" />

          <p class="mt-4">Excluded Users (optional)</p>
          <input placeholder="Add a user..." class="w-full mb-4" type="text" list="excluded_users_list" ref="excludedUsersInput" @input="handleUserAdd">
          <datalist id="excluded_users_list">
            <option v-for="user of filteredUserList" :value="user.name" :key="user.id" />
          </datalist>
          <div class="row flex-wrap">
            <Label v-for="user of activity.excludedUsers" :value="user.name" :key="user.id" @delete="removeUser(user)" class="mr-2 mb-1" :showDelete="true" size="sm" />
          </div>

          <p class="mt-4">Activity Location</p>
          <!-- <input type="text" placeholder="Title" v-model="activity.location.title" class="mb-2" />
          <input type="text" placeholder="Location URL" v-model="activity.location.link" /> -->
          <select v-model="activity.location.id" >
            <option :value="null">No location</option>
            <option v-for="location in locations" :value="location.id">(ID: {{ location.id }}): {{ location.title }}</option>
          </select>

          <div class="mt-6 w-full row items-baseline" >
            <div>
              <p class="required">Start at:</p>
              <Datepicker v-model="activity.startTime" :is24="false" />
            </div>

            <div class="ml-6">
              <p class="required">Finish at:</p>
              <Datepicker v-model="activity.endTime" :is24="false" />
            </div>
          </div>

          <p class="mt-4 mb-2">
            <input id="allow-check-in" type="checkbox" v-model="activity.allowCheckIn" class="mr-2">
            <label for="allow-check-in">Allow check-in</label>
          </p>

          <p class="mt-4 mb-2">
            <input id="sign-up-expires" type="checkbox" v-model="activity.signUpExpires" class="mr-2">
            <label for="sign-up-expires">Sign up expires</label>
          </p>

          <Datepicker v-if="activity.signUpExpires" v-model="activity.signUpExpiry" :is24="false" required />

          <p class="mt-4 mb-2">
            <input id="hidden" type="checkbox" v-model="activity.hidden" class="mr-2">
            <label for="hidden">Hidden</label>
          </p>

          <div class="mt-6 mb-2 col">
            <p class="required" for="self-sign-up">Activity type</p>
            <div>
              <input id="sign_up" type="radio" v-model="activity.type" value="sign_up">
              <label for="sign_up">Sign Up</label>
            </div>
            <div>
              <input id="assigned" type="radio" v-model="activity.type" value="assigned">
              <label for="assigned">Assigned</label>
            </div>
            <div>
              <input id="info" type="radio" v-model="activity.type" value="info">
              <label for="info">Info</label>
            </div>
          </div>

          <div v-if="['sign_up', 'assigned'].includes(activity.type)">
            <p>Options</p>
            <AdminActivityOption
                v-for="(option, index) of activity.options"
                :opt="option"
                :userList="allUsers"
                :index="index"

                :locations="locations"

                @removeOption="deleteOption(index)"
                @userListUpdated="updateOptionToUserMapping()"
            />
            <button class="good" @click="addOption()">Add option +</button>
          </div>
        </template>

        <div class="row mt-10">
          <button @click="saveActivity()" class="button primary mr-4 w-24">Save</button>
          <button @click="$router.back()" class="button w-24">Cancel</button>
          <div class="grow" />
          <button @click="$router.go()" class="button w-24">Reset</button>
        </div>
      </div>
    </div>

  </WideLayout>
</template>

<script>
import { useToast } from "vue-toastification";
import WideLayout from '../WideLayout.vue'
import AdminActivityOption from './AdminActivityOption.vue'
import HTMLEditor from "../shared/HTMLEditor.vue";
import ActivitySignUp from "../activity_details/ActivitySignUp.vue";
import ActivityAssigned from "../activity_details/ActivityAssigned.vue";
import ActivityInfo from "../activity_details/ActivityInfo.vue";
import MobileLayout from "../MobileLayout.vue";
import Label from '../shared/Label.vue';

export default {
  components : {
    MobileLayout,
    ActivityInfo,
    ActivityAssigned,
    ActivitySignUp,
    HTMLEditor,
    WideLayout,
    AdminActivityOption,
    Label
  },

  data() {
    return {
      toast: useToast(),
      allUsers: [],
      activity: {
        title: '',
        startTime: {},
        endTime: {},
        signUpExpiry: {},
        signUpExpires: false,
        allowCheckIn: false,
        description: '',
        hidden: false,
        type: '',
        optionIdToUserMapping: {},
        location: {
          id: null,
          title: '',
          link: ''
        },
        excludedUsers: []
      },
      locations: [],
      activityId: this.$route.params.id,
      loading: true,
    }
  },
  async mounted() {
    this.allUsers = await (await fetch('/api/users')).json()

    console.log({ allUsers: this.allUsers })
    // All possible locations
    const locationsResp = await fetch("/api/maps/")
    this.locations = await locationsResp.json()

    if (!this.activityId) {
      this.loading = false
      return
    }

    const resp = await fetch('/api/activities/' + this.activityId + '?for_edit=true')

    const result = await resp.json()
    result.startTime = new Date(result.startTime)
    result.endTime = new Date(result.endTime)
    result.signUpExpiry = result.signUpExpiry ? new Date(result.signUpExpiry) : new Date()

    this.activity = { ...(this.activity), ...result }
    this.sortByName(this.activity.excludedUsers)
    
    // Populate which location already chosen -- could be null!
    const activityLocationSelected = this.locations.find(location => location.id === this.activity.location.id)
    this.activity.location = { ...(this.activity.location), ...(activityLocationSelected || {}) }

    this.updateOptionToUserMapping()
    this.loading = false
  },
  computed: {
    filteredUserList() {
      const userChosen = (userToFind) => this.activity.excludedUsers.findIndex(user => user.id === userToFind.id) >= 0
      const filtered = this.allUsers.filter(u => !userChosen(u))
      const sorted = this.sortByName(filtered)

      return sorted
    }
  },
  methods: {
    debug() { this.updateOptionToUserMapping() },
    sortByName(arr) {
      return arr.sort((user1, user2) => user1.name > user2.name ? 1 : -1)
    },
    updateOptionToUserMapping() {
      const newMapping = {}
      this.activity.options?.forEach(opt => {
        const signed_up = opt.users || []

        signed_up.forEach(entry => {
          newMapping[opt.id] ||= []
          newMapping[opt.id].push({ name: entry.name.split(' ')[0], photo: '' })
        })
      })

      this.activity.optionIdToUserMapping = newMapping
    },
    handleUserAdd() {
      let chosen = this.$refs.excludedUsersInput.value
      if (chosen?.length > 0) {
        let userToAdd = this.allUsers.find(u => u.name === chosen)
        if (userToAdd) {
          this.activity.excludedUsers.push(userToAdd)
          this.$refs.excludedUsersInput.value = ''

          this.sortByName(this.activity.excludedUsers)
        }
      }
    },
    removeUser(user) {
      this.activity.excludedUsers = this.activity.excludedUsers.filter(u => u.email !== user.email)
    },
    addOption() {
      this.activity.options ||= []
      this.activity.options.push({
        id: Math.random(),
        title: '',
        description: '',
        url: '',
        users: [],
        limit: null,
        location: {}
      })
    },
    deleteOption(index) {
      this.activity.options.splice(index, 1)
    },
    is_valid() {
      const { activity } = this
      return activity.title &&
        activity.startTime?.getTime &&  // Check it's a date object
        activity.endTime?.getTime &&    // Check it's a date object
        activity.type //&&
        // this.eachOptionHasLocation()
        // TBD: aaaaaaa all or nothing???
        //   (
        //     this.activity.signUpExpiry.month &&
        //     this.activity.signUpExpiry.day &&
        //     this.activity.signUpExpiry.hour &&
        //     this.activity.signUpExpiry.minute &&
        //   ) || ...

        //   a & b & c & d   ||   !a & !b & !c & !d
        //   a & b & c & d   ||   !(a || b || c || d)
    },
    eachOptionHasLocation() {
      if (this.activity.type === 'info') return true

      for(let opt of (this.activity.options || [])) {
        if (!opt.location?.id) return false
      }
      return true
    },

    async saveActivity() {
      const unknownError = () => this.toast.error('Unknown error when trying to sign up.', { timeout: 5000 })

      // TODO: change the warn color, ew
      if (!this.is_valid()) {
        this.toast.warning('Please fill the required fields.', { timeout: 1500 })
        return
      }

      let url = `/api/activities`,
        method = 'POST'

      if (!!this.activityId) {
        url = `${url}/${this.activity.id}`
        method = 'PUT'
      }

      try {
        const resp = await fetch(url, { method: method, body: JSON.stringify({ ...(this.activity) }) })
        if (resp.ok) this.success()
        else         this.failure(resp).catch(unknownError)
      } catch(e) {
        unknownError()
      }
    },
    success() {
      this.toast.success(`Updated "${this.activity.title}"`, { timeout: 1500 })
      this.$router.push('/admin')
    },
    async failure(e) {
      let data = await e.json()

      switch (data.msg) {
        case 'LIMIT':
          this.toast.error('maybe ill need this')
          break;
        default:
          this.toast.error(`[${e.status}] There was an error when updating this activity: ${data.msg}`, { timeout: 5000 })
          break
      }
    }
  }
}
</script>