<template>
  <!-- TODO: Add responsive md- option (so admins can see a normal login page on desktop) -->
  <div id="gray-bg" class="h-full overflow-hidden overflow-y-auto bg-zinc-700">
    <div id="viewport" class="col relative h-full sm:max-h-[800px] w-screen max-w-screen-xs overflow-hidden mx-auto sm:mt-16 sm:rounded-xl bg-accent shadow">
      <Header v-if="!noHeader" :backButton="backButton" :title="title" />
      <slot></slot>
      <Footer v-if="!noHeader" />
    </div>
  </div>
</template>

<style scoped>
  #gray-bg {
    background-image: url(../img/bg.png);
    background-size: cover;
  }
</style>

<script>
import Header from './Header.vue'
import Footer from "./Footer.vue";

export default {
  props: ['noHeader', 'backButton', 'title'],
  components : {
    Footer,
    Header
  },

  data() {
    return { activities: [] }
  },
}
</script>
