<template>
  <div
      class="row radio-btn"
      :class="{ checked, 'cursor-pointer': !readOnly }"
      @click="!readOnly && $emit('selected')"
  >
    <input :class="{ 'mt-[-20px]': option.description, 'hidden': readOnly }" type="radio" :checked="checked" :disabled="readOnly" />
    <div class="col items-start w-full ml-4">
      <div class="row w-full justify-between">
        <div @click.stop @click="openLink(option.url)" class="row items-center mr-2 activity-link font-semibold" :class="{ 'underline cursor-pointer': hasURL }">
          <span>{{ option.title }}</span>
          <VueFeather v-if="hasURL" class="ml-1 pb-1" size="16" type="external-link" />
        </div>
        <!-- wow this check is tricky... because null >= 0 resolves to true hahaha also isNaN(null) is false :') -->
        <Label v-if="option.limit !== null" :color="'bg-transparent'">
          <template #content>
            <span :class="`font-semibold text-[9.5pt] ${colorForFullness}`">{{ attending.length }} / {{ option.limit }}</span>
          </template>
        </Label>
      </div>
      <div class="text-sm" v-html="option.description"></div>
      <LocationLink :location="option.location" />
      <p class="mt-1 text-xs text-gray-500">
        {{ listOfAttending }}
      </p>
    </div>
  </div>
</template>
<script>
import Label from "../shared/Label.vue";
import LocationLink from '../shared/LocationLink.vue';
export default {
  components: { Label, LocationLink },
  props: {
    readOnly: {
      type: Boolean
    },
    checked: {
      type: Boolean
    },
    option: {},
    attending: {
      type: Array
    }
  },
  methods: {
    openLink(url) {
      console.log('opening url', url)
      if (url) window.open(url, '_blank').focus()
    },
  },
  computed: {
    hasURL() {
      return this.option.url?.length > 0
    },
    listOfAttending() {
      // bilal please have mercy on this old woman's eyesight
      let list = '';
      if (this.attending && this.attending.length > 0) {
        list = `Going: ${this.attending.sort((u1, u2) => u1.name > u2.name ? 1 : -1).map(e => e.name).join(', ')}`
      } else if (!this.readOnly) {
        list = 'Be the first one!'
      }

      return list
    },
    // Different color based on how full an option is
    colorForFullness() {
      const { attending, option: { limit } } = this
      if (limit == null || limit === 0) return ''

      const percentage = attending.length / limit
      // TODO: use primary, accent, and green (#00d8a4) or blue (#00c9ff) from style guide
      if (percentage < 0.25) return 'text-green-500'
      else if (percentage < 0.5) return 'text-yellow-500'
      else if (percentage < 0.75) return 'text-orange-500'
      else return 'text-red-500'
    }
  }
}
</script>

<style scoped>
.activity-link {
  color: var(--lucal-primary)
}
</style>
