<!--Viewing more info about an activity only-->
<template>
  <div class="col px-5 py-5 overflow-y-auto">
    <div class="mb-2 text-3xl">{{ activity.title }}</div>
    <div class="mb-2 text-sm text-gray-500">
      <span>{{ activity.timeBlock }}</span>
    </div>
    <LocationLink :location="activity.location" />

    <div class="mt-2" v-html="activity.description"></div>
  </div>
</template>

<script>
import LocationLink from '../shared/LocationLink.vue';

export default {
  components: { LocationLink },
  props: ['activity'],
}
</script>
