<!-- A row for the options that are available within any activity -->
<template>
  <div class="col my-2 px-2 py-2 rounded-md border-2">
    <div class="row justify-evenly">
      <div class="col grow">
        <div class="mb-2 w-full row justify-between">
          <input class="self-end" type="text" v-model="opt.title" placeholder="Title"/>

          <div>
            <p class="mb-0">Limit</p>
            <input type="number" class="w-16" v-model="opt.limit" />
          </div>

        </div>
        <input class="mb-2" type="text" v-model="opt.url" placeholder="URL of an activity..."/>
        <HTMLEditor v-model="opt.description" />
      </div>

      <div class="col ml-2" style="width: 300px">
        <label for="activity_option_location">Choose a location (optional)</label>
        <select id="activity_option_location" v-model="opt.location.id" >
          <option :value="null">No location</option>
          <option v-for="location in locations" :value="location.id">(ID: {{ location.id }}): {{ location.title }}</option>
        </select>
      </div>
      <!-- <AdminLocationDetails class="ml-4" :location="opt.location" /> -->
    </div>
    <div class="mt-4 w-full">
      <button @click="showUsers = !showUsers" class="button">{{ showUsers ? 'Hide' : 'Show' }} users ({{ numUsers }}/{{opt.limit}})</button>
      <div class="row flex-wrap mt-4" v-if="showUsers">
        <button class="button good mr-2" @click="addAllUsers()">Add All Users</button>
        <button class="button danger" @click="removeAllUsers()">Remove All Users</button>

        <input placeholder="Add a user..." class="w-full mb-4 mt-8" type="text" list="employee_list" ref="newUserInput" @input="handleUserAdd">
        <datalist id="employee_list">
          <option v-for="user of filteredUserList" :value="user.name" :key="user.id" />
        </datalist>
        <Label v-for="user of opt.users" :value="user.name" :key="user.id" @delete="removeUser(user)" class="mr-2 mb-1" :showDelete="true" size="sm" />
      </div>
    </div>
    <button @click="$emit('removeOption')" class="right-0 ml-auto w-24 danger">Delete</button>
  </div>

</template>
<script>
import Label from '../shared/Label.vue';
import AdminLocationDetails from "./AdminLocationDetails.vue";
import HTMLEditor from "../shared/HTMLEditor.vue";

export default {
  components: {HTMLEditor, AdminLocationDetails, Label},
  props: ["opt", "userList", "locations"],
  data() {
    return {
      showUsers: false
    }
  },
  mounted() {
    this.opt.location ||= { id: null, title: 'New location', link: '' }
    this.sortByName(this.opt.users)
  },
  computed: {
    numUsers() {
      return this.opt.users.length
    },
    filteredUserList() {
      const userChosen = (userToFind) => this.opt.users.findIndex(user => user.id === userToFind.id) >= 0
      const filtered = this.userList.filter(u => !userChosen(u))
      const sorted = this.sortByName(filtered)

      return sorted
    }
  },
  methods: {
    sortByName(arr) {
      return arr.sort((user1, user2) => user1.name > user2.name ? 1 : -1)
    },
    addAllUsers() {
      this.opt.users = this.userList.sort((user1, user2) => user1.name > user2.name ? 1 : -1)
      this.$emit('userListUpdated')
    },
    removeAllUsers() {
      this.opt.users = []
      this.$emit('userListUpdated')
    },
    handleUserAdd(event) {
      let chosenUser = this.$refs.newUserInput.value
      if (chosenUser?.length > 0) {
        let userToAdd = this.userList.find(u => u.name === chosenUser)
        if (userToAdd) {
          this.opt.users.push(userToAdd)
          this.$refs.newUserInput.value = ''

          this.sortByName(this.opt.users)
        }
      }

      this.$emit('userListUpdated')
    },
    removeUser(user) {
      this.opt.users = this.opt.users.filter(u => u.email !== user.email)
      this.$emit('userListUpdated')
    }
  }
}
</script>