<!--A cute lil' label-->
<template>
  <div
      class="row align-center rounded-md text-white px-2 py-1"
      :class="`text-${size} ${color} ${circle ? 'rounded-full' : ''}`"
  >
    <template v-if="hasContentSlot">
      <slot name="content"></slot>
    </template>

    <template v-else>
      <span>{{ value }}</span>
      <VueFeather v-if="showDelete" @click="$emit('delete')" type="x-square" size="19" class="ml-1 cursor-pointer" />
    </template>
  </div>
</template>
<script>

export default {
  props: {
    value: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'bg-gray-500'
    },
    size: {
      type: String,
      default: 'xs'
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasContentSlot() { return !!this.$slots.content }
  }
}
</script>