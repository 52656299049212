<template>
  <div class="h-20 row justify-evenly w-full bg-zinc-100 border-t-2">
    <div @click="$router.push('/schedule')" class="bottomNavOption noBorder" :class="{ 'active': isActive('schedule') }">
      <VueFeather type="calendar" size="22" />
      <!-- <span class="bottomNavText">My Calendar</span> -->
    </div>
    <div @click="$router.push('/mapping')" class="bottomNavOption" :class="{ 'active': isActive('mapping') }">
      <VueFeather type="map" size="22" />
      <!-- <span class="bottomNavText">Map Guide</span> -->
    </div>
    <!-- TODO: Activate once the browse page is bucketed and filter function active -->
    <div @click="$router.push('/all')" class="relative bottomNavOption" :class="{ 'active': isActive('all') }">
      <VueFeather type="activity" size="22" />
      <Label v-if="$store.state.numActivitiesRequiringSignup > 0" class="absolute top-[-10px] right-[-7px]" color="bg-red-500" :circle="true">
        <template #content>
          <div class="font-semibold">
            {{ $store.state.numActivitiesRequiringSignup }}
          </div>
        </template>
      </Label>
<!--      <span class="bottomNavText">All Events</span>-->
    </div>
    <div @click="$router.push('/profile')" class="bottomNavOption" :class="{ 'active': isActive('profile') }">
      <VueFeather type="user" size="22" />
      <!-- <span class="bottomNavText">My Profile</span> -->
    </div>
  </div>
</template>
<script>
import Label from "./shared/Label.vue";
export default {
  components: {Label},
  methods: {
    isActive(opt) {
      return this.$route.path.includes(opt)
    }
  }
}
</script>
