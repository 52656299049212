<template>
<li class="row justify-between w-3/4 mx-auto mb-2 px-4 py-4 rounded-md shadow bg-white">
  <div>
    <p class="font-bold text-lg">{{ item.title }}</p>
    <p class="text-sm">{{ item.timeBlock }}</p>
    <Label v-if="item.hidden" value="Hidden" class="w-[52px]" />
  </div>

  <div class="row items-start">
    <button @click="goToActivityEdit()" class="good icon-btn mr-5">
      <VueFeather type="edit" size="20" />
    </button>
    <button @click="deleteActivity()" class="danger icon-btn">
      <VueFeather class="" type="x" size="20" />
    </button>
  </div>
</li>
</template>

<script>
import { useToast } from "vue-toastification";
import Label from "../shared/Label.vue";

export default {
    props: ["item"],
    data() {
        return {
            toast: useToast(),
        };
    },
    methods: {
        goToActivityEdit() {
            this.$router.push("/admin/activity/edit/" + this.item.id);
        },
        async deleteActivity() {
            if (confirm(`Are you sure you want to delete "${this.item.title}"?`)) {
                const unknownError = () => this.toast.error("Unknown error when trying to delete activity.", { timeout: 5000 });
                try {
                    const resp = await fetch(`/api/activities/${this.item.id}`, { method: "DELETE" });
                    if (resp.ok) {
                        this.success();
                    }
                    else
                        this.failure(resp).catch(unknownError);
                }
                catch (e) {
                    unknownError();
                }
            }
        },
        success() {
            window.location.reload()
        },
        async failure(e) {
            let data = await e.json();
            switch (data.msg) {
                case "LIMIT":
                    this.toast.error("maybe ill need this");
                    break;
                default:
                    this.toast.error(`[${e.status}] There was an error when updating this activity: ${data.msg}`, { timeout: 5000 });
                    break;
            }
        }
    },
    components: { Label }
}
</script>