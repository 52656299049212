<template>
  <WideLayout>
    <div class="row justify-between items-center mb-5 py-2 border-b border-zinc-400">
      <span class="text-2xl mr-4">Rallie Admin</span>
      <div class="grow" />
      
      <div>
        <input id="activities_list" type="radio" v-model="tabSelected" value="activities">
        <label class="ml-2" for="activities_list">Activities</label>
      </div>

      <div class="ml-4">
        <input id="locations_list" type="radio" v-model="tabSelected" value="locations">
        <label class="ml-2" for="locations_list">Locations</label>
      </div>

      <div class="grow" />
      <button class="button" @click="logout">Logout</button>
    </div>

    <!-- TODO: Group by day (they're already sorted) -->
    <ul>
      <AdminActivityRows v-if="tabSelected === 'activities'" />
      <AdminLocationRows v-if="tabSelected === 'locations'" />
    </ul>
  </WideLayout>
</template>

<script>
import WideLayout from '../WideLayout.vue'
import AdminActivityRows from './AdminActivityRows.vue'
import AdminLocationRows from "./AdminLocationRows.vue"

export default {
  components : {
    WideLayout,
    AdminActivityRows,
    AdminLocationRows
  },
  data() {
    return {
      tabSelected: "activities"
    }
  },
  methods: {
    switchTab(tab) {
      this.tabSelected = tab
    },
    async logout() {
      let resp = await fetch('/users/sign_out', { method: 'DELETE' })
      if(resp.ok) {
        await this.$router.push('/')
        window.location.reload()
      }
      else {
        alert('Error logging out.')
        console.error('Failed to logout', resp)
      }
    },
  }
}
</script>