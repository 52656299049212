<template>
  <div class="column mb-2 px-4 py-4 rounded-md shadow bg-white w-3/4 mx-auto">
    <div class="row justify-between">
      <div>
        <p v-if="item.title" class="font-bold text-lg">{{ item.title }}</p>
        <p v-else><i>No title</i></p>

        <p class="text-sm">{{ item.description }}</p>
        
        <div class="row">
          <Label class="mr-1" v-if="item.id" :value="'id: ' + item.id" size="sm" style="width: max-content" />
          <Label :value="item.type" size="sm" style="width: max-content" />
        </div>
      </div>

      <div class="row items-start">
        <button @click="editEnabled = true" class="good icon-btn mr-5">
          <VueFeather type="edit" size="20" />
        </button>
        <button @click="deleteLocation()" class="danger icon-btn">
          <VueFeather class="" type="x" size="20" />
        </button>
      </div>
    </div>

    <div v-if="editEnabled || editing" class="column mt-2 p-5 border-2">
      <div class="mb-2">
        <input v-model="title" type="text" placeholder="Title" />
      </div>
      <div class="mb-2">
        <textarea v-model="description" type="text" class="w-full" placeholder="Description" rows="5" />
      </div>
      <input v-model="url" type="text" placeholder="URL" class="mr-4" />
      <input v-model="lat_long" type="text" placeholder="lat/long" class="mr-4" />

      
      <select v-model="type" >
        <option value="accomodation">Accomodation</option>
        <option value="restaurant">Restaurant</option>
        <option value="bar">Bar</option>
        <option value="breakfast">Breakfast</option>
        <option value="pool">Pool</option>
        <option value="sports">Sports</option>
        <option value="spa">Spa</option>
        <option value="meeting">Meeting</option>
        <option value="grocery">Grocery</option>
        <option value="other" selected="selected">Other</option>
      </select>
    </div>

    <div v-if="editEnabled || editing" class="row mt-4 justify-center">
      <!-- <div class="grow" /> -->
      <button @click="cancel()" class="button mr-4">Cancel</button>
      <!-- <div class="grow" /> -->
      <button @click="save()" class="button ml-4 primary">Save</button>
      <!-- <div class="grow" /> -->
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import Label from "../shared/Label.vue";

export default {
  props: ["item", "onReload", "editing", "onCancel"],
  data() {
    let lat_long = ""
    if (this.item.latitude && this.item.longitude) {
      lat_long = `${this.item.latitude}, ${this.item.longitude}`
    }

    console.log("item url", this.item.url)
    return {
      toast: useToast(),
      editEnabled: false,
      
      lat_long: lat_long,

      title: this.item.title,
      description: this.item.description,
      type: this.item.type,
      url: this.item.url
    };
  },
  computed: {
    latitude() {
      return parseFloat(this.lat_long.split(",")[0])
    },
    longitude() {
      return parseFloat(this.lat_long.split(",").at(-1).trim())
    }
  },
  methods: {
    goToLocationEdit() {
      this.$router.push("/admin/activity/edit/" + this.item.id);
    },
    cancel() {
      this.editEnabled = false

      this.title =  this.item.title,
      this.description = this.item.description,
      this.type = this.item.type,

      this.lat_long = `${this.item.latitude}, ${this.item.longitude}`
      this.url = this.item.url

      this.onCancel && this.onCancel()
    },
    async save() {
      try {
        const url = this.item.id ? `/api/maps/${this.item.id}` : "/api/maps" 
        const method = this.item.id ? "PATCH" : "POST"

        const resp = await fetch(
          url, 
          { 
            method: method, 
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              marker: {
                title: this.title,
                description: this.description,
                type: this.type,
                latitude: this.latitude,
                longitude: this.longitude,
                url: this.url
              }
            })
          }
        )

        if (resp.ok) { this.success() } 
        else {
          this.toast.error("Error updating location. Check all fields are valid.", { timeout: 8000 })
        }
      } catch (error) {
        console.error("Failed to update", error)
        this.toast.error("Error when trying to update location. Check console logs.", { timeout: 8000 })
      }
    },
    async deleteLocation() {
      if (confirm(`Are you sure you want to delete the location "${this.item.title}"?`)) {
        const unknownError = () => this.toast.error("Unknown error when trying to delete location.", { timeout: 5000 });
        try {
          const resp = await fetch(`/api/maps/${this.item.id}`, { method: "DELETE" });
          if (resp.ok) {
            this.success();
          }
          else {
            console.error('resp not okay', resp)
            this.failure(resp).catch(unknownError);
          }
        }
        catch (e) {
          console.error('unknown error', e)
          unknownError();
        }
      }
    },
    success() {
      this.toast.success("Done!", { timeout: 3000 })
      this.editEnabled = false
      this.onReload()
    },
    async failure(e) {
      let data = await e.json();
      switch (data.msg) {
        case "LIMIT":
          this.toast.error("maybe ill need this");
          break;
        default:
          this.toast.error(`[${e.status}] Error when modifying this location: ${data.msg}`, { timeout: 8000 });
          break;
      }
    }
  },
  components: { Label }
}
</script>