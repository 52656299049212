<template>
  <div class="col">
    <p class="required">Location title</p>
    <input class="mb-4" id="location_title" type="text" v-model="location.title" placeholder="Location title" />

    <label for="location_link">Location URL </label>
    <input id="location_link" type="text" v-model="location.link" placeholder="Google Location URL" />
    <p class="mt-1 text-xs text-gray-500">
      Using Jenelle's map
      <a
        href="https://www.google.com/maps/d/embed?mid=1ammXOHWVWskCXuDFqqrPg3XgT6j65Uo&ehbc=2E312F"
        target="_blank"
        class="underline"
      >
        here.
      </a>
      </p>

  </div>
</template>
<script>
export default {
  props: ['location']
}
</script>