<template>
<div class="column">
  <div class="row px-5 mb-6">
    <div class="grow" />
    <p class="text-2xl">Activities</p>
    <div class="grow" />
    <button class="button primary" @click="goToActivityNew()">New Activity</button>
    <div class="grow" />
  </div>
  <ul>
    <AdminActivityRow v-for="(item) in activities" :item="item" />
  </ul>
</div>
</template>
<script>
import AdminActivityRow from './AdminActivityRow.vue'

export default {
  components: {
    AdminActivityRow
  },

  data() {
    return { activities: [] }
  },

  async mounted() {
    let resp = await fetch('/api/activities?showAll=true')
    this.activities = await resp.json()
  },

  methods: {
    goToActivityNew() {
      this.$router.push('/admin/activity/new/')
    },
  }
}
</script>