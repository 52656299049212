<template>
  <menu class="w-full row h-20 p-8 items-center text-white">
    <span v-if="backButton" @click="$router.back()" class="col justify-center items-center h-8 w-8 cursor-pointer" >
      <VueFeather type="arrow-left" />
    </span>
    <div v-else class="font-bold text-2xl">{{ title }}</div>
  </menu>
</template>

<script>
export default {
  props: ['backButton', 'title'],
  data() {
    return {
    }
  },
  computed: {
    firstName() {
      return this.$store.state.user.first_name
    }
  }
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>