import { createWebHashHistory, createRouter } from 'vue-router'
import App from "../../frontend/components/App.vue";
import Schedule from "../../frontend/components/Schedule.vue";
import MapGuide from "../../frontend/components/MapGuide.vue";
import AllActivities from "../../frontend/components/AllActivities.vue";
import Profile from "../../frontend/components/Profile.vue";
import Admin from "../../frontend/components/admin/Admin.vue";
import AdminActivity from "../../frontend/components/admin/AdminActivity.vue";
import ActivityDetails from "../../frontend/components/ActivityDetails.vue";
import PageNotFound from "../../frontend/components/PageNotFound.vue";
import { createApp } from 'vue/dist/vue.esm-bundler';
import { createStore } from 'vuex'
import Toast, { POSITION } from "vue-toastification";
import VueFeather from 'vue-feather';
import Datepicker from '@vuepic/vue-datepicker';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

import "vue-toastification/dist/index.css";
import '@vuepic/vue-datepicker/dist/main.css'

Bugsnag.start({
  apiKey: 'e0358a9f652524e3efa976dec8ae58db',
  plugins: [new BugsnagPluginVue()],
  onError: function(event) {
    event.addMetadata('user', window.Lucal?.user_info)
  }
})
const bugsnagVue = Bugsnag.getPlugin('vue')

const routes = [
  { path: '/', component: App },

  { path: '/admin', component: Admin, meta: { requiresAdmin: true } },
  // TODO: changing the path from one to another doesn't reload the component
  //       maybe use a different  hook than mo9unted? would that work?
  { path: '/admin/activity/edit/:id', component: AdminActivity, meta: { requiresAdmin: true } },
  { path: '/admin/activity/new', component: AdminActivity, meta: { requiresAdmin: true } },

  // 404 -- Must be at the end!
  { path: '/:pathMatch(.*)*', component: PageNotFound }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

/** Prevent unauthenticated user from entering */
router.beforeEach((to, from) => {
  if(to.meta.requiresAuth && !window.Lucal?.user_info) {
    console.log('user not authenticated, returning to home')
    return {
      path: '/'
    }
  }

  if(to.meta.requiresAdmin && !window.Lucal.user_info?.is_admin) {
    console.log('admin not authenticated, returning to home')
    return {
      path: '/'
    }
  }
})

const store = createStore({
  state() {
    return {
      user: window.Lucal?.user_info,
      allActivities: [],
      numActivitiesRequiringSignup: 0
    }
  },
  mutations: {
    async loadAllActivities() {
      let numSignup = 0
      const activityNeedsSignUp = (act) => act.type === 'sign_up' && !act.chosenOption

      if (this.state.user) {
        let resp = await fetch('/api/activities')
        if (resp.ok) {
          // User could be logged out here? Better safe than sorry...
          this.state.allActivities = await resp.json()
          this.state.allActivities.forEach(act => {
            if (activityNeedsSignUp(act))
                numSignup += 1
          })

          this.state.numActivitiesRequiringSignup = numSignup
        }
      }
    }
  },
  getters: {
    loggedIn (state) { return !!state.user }
  }
})

const app = createApp({
  mounted() {
    this.$store.commit('loadAllActivities')
  }
})
app.use(bugsnagVue)
app.use(router)
app.use(store)
app.use(Toast, {
  transition: "Vue-Toastification__fade",
  position: POSITION.BOTTOM_CENTER,
  timeout: 3500,
  maxToasts: 2,  // On mobile, any more would seem excessive
  hideProgressBar: true
})
app.component('Datepicker', Datepicker)
app.component('VueFeather', VueFeather)
app.mount('#app')