<template>
  <div class="column">
    <div class="row px-5 mb-6">
      <div class="grow" />
      <p class="text-2xl">Locations</p>
      <div class="grow" />
      <button class="button primary" @click="creatingNewLocation = true">New Location</button>
      <div class="grow" />
    </div>
    <ul>
      <AdminLocationRow v-if="creatingNewLocation" :item="newLocation" :onReload="doneCreatingNewLocation" :onCancel="doneCreatingNewLocation" :editing="true" />
      <AdminLocationRow v-for="(item) in locations" :key="item.id" :item="item" :onReload="getData" :editing="false" />
    </ul>
  </div>
  </template>
  <script>
  import AdminLocationRow from './AdminLocationRow.vue'
  
  export default {
    components: {
      AdminLocationRow
    },
  
    data() {
      return { 
        locations: [],
        newLocation: {
          title: "",
          description: "",
          type: "other",
          latitude: null,
          longitude: null,
          url: ""
        },
        creatingNewLocation: false
      }
    },
  
    async mounted() {
      this.getData()
    },
  
    methods: {
      async doneCreatingNewLocation() {
        this.creatingNewLocation = false
        this.newLocation = {
          title: "",
          description: "",
          type: "other",
          latitude: null,
          longitude: null,
          url: ""
        }

        await this.getData()
      },
      async getData() {
        let resp = await fetch('/api/maps')
        this.locations = await resp.json()
      }
    }
  }
  </script>