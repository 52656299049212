<!-- Entrypoint of the app, what the user first sees (Splash) -->
<template>
  <MobileLayout :noHeader="true">
    <section :class="`h-full w-full bg-center bg-logged-${loggedIn ? 'in' : 'out'}`">
      <div class="h-full w-full bg-neutral-300/40">
        <div class="col items-center absolute bottom-0 pt-12 pb-16 rounded-t-super bg-white opacity-95">
          <img class="w-3/5 mb-6" src="../img/lumerate-logo.png">

          <template v-if="loggedIn">
            <p class="my-8 text-2xl">Welcome, {{$store.state.user.first_name}}!</p>
            <div class="dot-flashing mb-20"></div>
          </template>

          <template v-else>
            <p class="text-2xl font-semibold">Catalyze your adventure</p>
            <p class="text-xl">@ Punta Cana 2023</p>
            <div class="row items-center bg-zinc-100 px-4 py-2 rounded-md shadow mt-8 border-2 border-primary cursor-pointer" @click="login()">
              <img class="inline w-6 align-middle mr-4" src="../img/g-logo.png">
              <span class="text-zinc-700 text-xl">Sign in with Google</span>
            </div>
          </template>

        </div>
      </div>
    </section>
  </MobileLayout>
</template>

<style scoped>
  .bg-logged-out {
    background-image: url(../img/punta_cana.jpg);
    /* background-position: 70% 35%; */
    /* background-size: 200% auto; */
    background-repeat: no-repeat;
  }
  .bg-logged-in {
    /* background-position: 45% 0%; */
    /* background-size: 140% auto;
    background-repeat: no-repeat; */
    background-image: url(../img/punta_cana.jpg);
  }
</style>

<script>
import MobileLayout from "./MobileLayout.vue";

export default {
  components : {
    MobileLayout
  },

  data() {
    return {
    }
  },
  mounted() {
    console.log('Loaded user info:', JSON.stringify(this.$store.state.user, null, 2))

    if (this.loggedIn) {
      console.log('user logged in, will navigate in 1500ms')
      setTimeout(() => {
        this.$router.push('/admin')
      }, 1500);
    } else {
      console.log("user not logged in, no route change", this.$store.state)
    }
  },
  methods: {
    login() {
      document.getElementById('google_oauth_csrf').click()
    },
  },
  computed: {
    loggedIn() { return !!this.$store.state.user }
  }
}
</script>
