<!--A cute lil' location link-->
<template>
  <a v-if="location?.title"
    class="row items-center mt-1 text-sm text-gray-500"
    :class="{ 'underline cursor-pointer': hasURL }"
    @click.stop.prevent="openToMap(location.link)"
    :href="location.link"
    >

    <VueFeather type="map-pin" size="14" class="mr-1" />
    <span>{{ location.title }}</span>
    <VueFeather v-if="hasURL" class="ml-1" size="13" type="external-link" />
  </a>
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
      required: true
    },
  },
  methods: {
    openLink(url) {
      console.log('opening url', url)
      if (url) window.open(url, '_blank').focus()
    },
    openToMap(url) {
      if (!url?.startsWith('https://www.google.com/maps/d/'))
        this.openLink(url)
      else
        this.$router.push({ path: '/mapping', query: { url } })
    }
  },
  computed: {
    hasURL() {
      return this.location.link?.length > 0
    },
  }
}
</script>