<!--Sign up for an activity, with options to choose from!-->
<template>
  <div class="col px-5 py-5 overflow-y-auto">
    <div class="mb-4 text-center p-5 bg-primary rounded-t-super text-white">
      <div class="text-2xl">{{ activity.title }}</div>
      <div class="text-xs italic">{{ activity.timeBlock }}</div>
    </div>
    <LocationLink :location="activity.location" class="mb-2" />
    <div v-if="activity.description?.length > 0" class="mb-4 text-left" v-html="activity.description" />

    <div class="col w-full mb-8">
      <ActivityOption
          v-for="option in activity.options"
          :checked="selectedOption === option.id"
          :option="option"
          :attending="activity.optionIdToUserMapping[option.id] || []"
          @selected="selectedOption = option.id"
      />
    </div>

    <div class="row w-full justify-center">
      <button class="button primary" @click="signUp()">Sign Me Up!</button>
    </div>
  </div>
</template>
<script>
import { useToast } from "vue-toastification";
import ActivityOption from "./ActivityOption.vue";
import LocationLink from "../shared/LocationLink.vue";

export default {
  components: {ActivityOption, LocationLink},
  props: ['activity', 'disabled'],
  data() {
    return {
      toast: useToast(),
      selectedOption: this.activity.chosenOption
    }
  },
  methods: {
    async signUp() {
      if (this.disabled) return

      const unknownError = () => this.toast.error('Unknown error when trying to sign up.', { timeout: 5000 })

      if (this.selectedOption) {
        try {
          const resp = await fetch(`/api/activities/${this.activity.id}/sign_up/${this.selectedOption}`, { method: 'PUT' })
          if (resp.ok) this.success()
          else         this.failure(resp).catch(unknownError)
        } catch(e) {   unknownError() }
      }
      else {
        this.toast.error('You must select at least one option!')
      }
    },
    success() {
      const chosenTitle = this.activity.options.find(e => e.id === this.selectedOption).title
      this.toast.success(`Signed up for "${chosenTitle}".`, { timeout: 1500 })
      this.$router.back()

      // Hacky: load what user needs to sign up for again...
      this.$store.commit('loadAllActivities')
    },
    async failure(e) {
      console.error('Failed to sign up', e)
      let data = await e.json()

      switch (data.msg) {
        case 'LIMIT':
          this.toast.error('This option is full, please choose another.')
          break;
        default:
          this.toast.error(`[${e.status}] There was an error when trying to sign up: ${data.msg}`, { timeout: 5000 })
          break
      }
    }
  }
}
</script>