<!--A read-only activity that was assigned, shows who is under what group-->
<template>
  <div class="col px-5 py-5 overflow-y-auto">
    <div class="mb-4 text-center p-5 bg-primary rounded-t-super text-white">
      <div class="text-2xl">{{ activity.title }}</div>
      <div class="text-xs italic">{{ activity.timeBlock }}</div>
    </div>
    <LocationLink :location="activity.location" class="mb-2" />
    <div v-if="activity.description?.length > 0" class="mb-4 text-left" v-html="activity.description" />

    <div class="col w-full mb-2">
      <ActivityOption
          v-for="option in activity.options"
          :checked="selectedOption === option.id"
          :option="option"
          :attending="activity.optionIdToUserMapping[option.id] || []"
          :readOnly="true"
      />
    </div>
    <p class="mb-6 text-xs text-gray-600">
      This option has been selected for you.
      Please reach out to a member of T&amp;C for any questions.
    </p>
  </div>
</template>
<script>
import ActivityOption from "./ActivityOption.vue";
import LocationLink from "../shared/LocationLink.vue";
export default {
  components: {ActivityOption, LocationLink},
  props: ['activity'],
  data() {
    return {
      selectedOption: this.activity.chosenOption
    }
  }
}
</script>