<template>
<!--  Editor menu-->
  <div v-if="editor" class="row pl-1 mb-1">
    <div
        @click="editor.chain().focus().toggleItalic().run()"

        class="p-1 row items-center cursor-pointer"
        :class="{ 'bg-gray-300': editor.isActive('italic') }"
    >
      <VueFeather type="italic" />
    </div>

    <div
        @click="editor.chain().focus().toggleBold().run()"

        class="p-1 ml-1 row items-center cursor-pointer"
        :class="{ 'bg-gray-300': editor.isActive('bold') }"
    >
      <VueFeather type="bold" />
    </div>

    <div
        @click="editor.chain().focus().toggleStrike().run()"

        class="p-1 ml-1 row items-center cursor-pointer"
        :class="{ 'bg-gray-300': editor.isActive('strike') }"
    >
      <VueFeather type="minus" />
    </div>

    <div class="ml-4" />
    <div
        @click="editor.chain().focus().toggleOrderedList().run()"

        class="p-1 ml-1 row items-center cursor-pointer"
        :class="{ 'bg-gray-300': editor.isActive('orderedList') }"
    >
      <VueFeather type="list" />
    </div>
  </div>
  <EditorContent :editor="editor" class="border-2 rounded-md min-h-24" />
</template>
<script>
import StarterKit from '@tiptap/starter-kit'
import { Markdown } from 'tiptap-markdown';
import { Editor, EditorContent } from '@tiptap/vue-3'

export default {
  components: {
    EditorContent,
  },

  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      editor: null,
    }
  },
  watch: {
    modelValue(value) {
      const isSame = this.editor.storage.markdown.getMarkdown() === value

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Markdown,
      ],
      content: this.modelValue,
      onUpdate: () => {
        this.$emit('update:modelValue', this.editor.storage.markdown.getMarkdown())
      },
    })
  },
  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>
<style>
.ProseMirror {
  padding: 5px;
  min-height: 120px;
}
</style>